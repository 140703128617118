import React, { useEffect } from "react";

import { NextSeo } from "next-seo";

import { NoContentFound, DraggableBlock, useSite } from "@wazoe/elements";

import getStaticPageProps from "../utils/getStaticProps";
import getStaticPagePaths from "../utils/getStaticPaths";

export const getStaticPaths = getStaticPagePaths;
export const getStaticProps = getStaticPageProps;

export default function Page({
  page,
  header,
  footer,
  pageData,
  site,
  textPlaceholders,
  locale,
}) {
  const { setLocale } = useSite();
  const contentArray = [...header, ...pageData.content, ...footer];

  useEffect(() => {
    setLocale(locale);
  }, [locale, setLocale]);

  return (
    <>
      <NextSeo
        title={`${
          page.type === "repeatableItem"
            ? textPlaceholders["%NAME%"]
            : pageData.name
        } | ${site.name}`}
        description={pageData.seo?.seoDescription}
        additionalMetaTags={[
          {
            ...(pageData.seo?.seoKeywords && {
              property: "keywords",
              content: pageData.seo?.seoKeywords,
            }),
          },
        ]}
      />

      {contentArray?.length === 0 && <NoContentFound />}
      {contentArray.map((content) => {
        return (
          <DraggableBlock
            key={content.id}
            {...{
              elementProps: content.props,
              ...{ forms: site.forms },
              ...(content.type.includes("repeatable") && {
                repeatables: site.repeatables,
              }),
              ...(content.type.includes("navbar") && {
                menus: site.menus,
                pages: site.pages,
                activePage:
                  pageData?.path === "/"
                    ? `/${locale?.toLowerCase()}`
                    : `/${locale?.toLowerCase()}${pageData?.path}`,
              }),
            }}
          />
        );
      })}
    </>
  );
}
